import { useContext } from "react"
import { MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemCheckbox from "../elements/Checkbox"
import React from "react"
import ElemCheckboxProducts from "../elements/CheckboxProducts"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const Product: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="mx-auto max-w-[900px]">
			<div className="text-center">
				<h2 className="mb-12 pt-8 text-center font-Veener text-[28px] uppercase text-white lg:mt-24 lg:pt-0">Welche Sorte hat dich erfrischt?</h2>
			</div>
			<div className="align-items-center  grid grid-cols-3 items-center justify-items-center gap-4 ">
				<div className="text-center">
					<img src={process.env.PUBLIC_URL + "/images/volvic/Himbeere.png"} className="z-2 relative  mx-auto max-w-[50%] lg:ml-12 lg:max-w-[60%]" />
					<ElemCheckboxProducts
						label={<>RECHARGE*¹</>}
						props={{
							type: "radio",
							name: "product",
							value: globalState.product,
							required: "required",
							onClick: e => formElementChanged(e, "product"),
							"data-value": "a",
						}}
						className="mt-6"
					/>
				</div>
				<div>
					{" "}
					<img src={process.env.PUBLIC_URL + "/images/volvic/Zitrone.png"} className="z-2 relative  mx-auto max-w-[50%] lg:ml-12 lg:max-w-[60%]" />
					<ElemCheckboxProducts
						label={<>AWAKE*²</>}
						props={{
							type: "radio",
							name: "product",
							value: "globalState.product",
							onClick: e => formElementChanged(e, "product"),
							"data-value": "b",
						}}
						className="mt-6"
					/>
				</div>
				<div>
					{" "}
					<img src={process.env.PUBLIC_URL + "/images/volvic/Pfirsich.png"} className="z-2 relative  mx-auto max-w-[50%] lg:ml-12 lg:max-w-[60%]" />
					<ElemCheckboxProducts
						label={<>ACTIVE*³</>}
						props={{
							type: "radio",
							name: "product",
							value: globalState.product,
							onClick: e => formElementChanged(e, "product"),
							"data-value": "c",
						}}
						className="mt-6"
					/>
				</div>
			</div>
			<div className="mb-12 lg:mb-2">
				{validation["invalid_product"] ? (
					<p className="mx-auto mb-12 mb-12 mt-6 max-w-[80%] rounded-[5px] bg-white py-2 text-center  text-red lg:max-w-[50%]">Bitte ein Produkt auswählen</p>
				) : (
					""
				)}
			</div>
			<div className="text-[14px] lg:mt-6 lg:mb-10">
				<p className="mb-2 text-white">¹ Volvic RECHARGE: Pantothensäure, Vitamin B6 , B12 und Magnesium tragen zur Verringerung von Müdigkeit und Ermüdung bei.</p>
				<p className="mb-2 text-white">² Volvic AWAKE: Niacine, Vitamin B6, Folsäure und Magnesium tragen zur Verringerung von Müdigkeit und Ermüdung bei.</p>
				<p className="mb-12 text-white lg:mb-2">³ Volvic ACTIVE: Vitamin C, B12, Biotin und Magnesium tragen zu einem normalen Energiestoffwechsel bei.</p>
			</div>
			<div className="mx-auto mb-1 grid max-w-[900px] grid-cols-1 items-start gap-1 lg:grid-cols-[1fr_1fr] lg:gap-5">
				<ElemInput
					label={"Kaufbetrag in € exkl. Pfand*"}
					props={{
						type: "number",
						step: "0.01",
						min: "0.1",
						max: MAX_PRODUCT_PRICE,
						name: "purchaseAmount",
						value: globalState.purchaseAmount,
						placeholder: "Kaufbetrag in € exkl. Pfand*",
						required: "required",
						onChange: e => formElementChanged(e, "purchaseAmount"),
					}}
					hasError={validation["invalid_purchaseAmount"] ? true : false}
					className="col-span-2 lg:col-span-1"
					suffix="EUR"
					errMsg={validation["invalid_purchaseAmount_errMsg"] || "Bitte Kaufbetrag eingeben. (max. 5,00 EUR)"}
				/>
				<ElemInput
					label={"Kaufdatum*"}
					props={{
						type: "date",
						name: "purchaseDate",
						value: globalState.purchaseDate,
						required: "required",
						placeholder: "Kaufdatum*",
						min: "2024-06-17",
						max: "2024-09-30",
						minLength: 1,
						onChange: e => formElementChanged(e, "purchaseDate"),
					}}
					// TODO: Datepicker
					hasError={validation["invalid_purchaseDate"] ? true : false}
					className="col-span-2 lg:col-span-1"
					errMsg={validation["invalid_purchaseDate_errMsg"] || "Bitte Kaufdatum eingeben."}
				/>
			</div>
		</section>
	)
}

export default Product
